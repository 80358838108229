<template>
  <h1>Middagr<br>🥩 🥔 🌿</h1>

  <button @click="tryYourLuck()">Middagisér!</button>

  <transition name="glide-in" appear>
    <div v-if="dinner != null">
      <div class="dinner">
        <h2>Du skal ha:</h2>

        <div>
          <a
            target="_BLANK"
            :href="`https://google.com/search?q=${dinner.primary.method} ${dinner.primary.food} oppskrift`"
          >
            <div class="dinner-part">
              <span
                >{{ dinner.primary.method.capitalize() }}
                {{ dinner.primary.food }}</span
              >
            </div>
          </a>

          <div class="not-dinner-part">med</div>
          <div class="dinner-part">
            <span
              >{{ dinner.secondary.method }} {{ dinner.secondary.food }}</span
            >
          </div>
          <div class="not-dinner-part">og</div>
          <div class="dinner-part">
            {{ dinner.tertiary.method }} {{ dinner.tertiary.food }}
          </div>
        </div>
      </div>
      <div>
        <h2>Hva syns du om forslaget?</h2>
        <transition name="glide-in" appear>
          <div>
            <div class="button-group" v-if="!feedbackGiven">
              <button class="positive" @click="sendFeedback(true)">Jej!</button>
              <button class="negative" @click="sendFeedback(false)">
                Buu!
              </button>
            </div>
            <div v-if="feedbackGiven">
              <strong>Takk for din tilbakemelding!</strong>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      dinner: null,
      feedbackGiven: false,
    };
  },
  mounted() {},
  methods: {
    tryYourLuck() {
      this.dinner = null;

      axios.get("http://140.82.44.31:5000").then((response) => {
        this.dinner = response.data;
        this.feedbackGiven = false;
      });
    },
    sendFeedback(didLike) {
      // let feedback = {
      //   ...this.dinner,
      //   didLike: didLike,
      // };
      // console.log(feedback);

      axios
        .post("http://140.82.44.31:5000/feedback", {
          ...this.dinner,
          didLike: true,
        })
        .then(() => {
          this.feedbackGiven = true;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
#app {
  max-width: 800px;
  margin: auto;
  font-family: Georgia, "Times New Roman", Times, serif;
}

h1,
h2 {
  text-align: center;
}

.button-group {
  display: flex;
  justify-content: space-around;
}

$button-color: darksalmon;

button {
  padding: 10px;
  margin: 30px auto;
  display: block;
  border-radius: 2px;
  cursor: pointer;
  border: 2px solid $button-color;
  color: $button-color;
  font-size: 1.125em;
  background-color: white;
  font-weight: 600;
  // letter-spacing: 1px;
  text-transform: uppercase;

  &:hover {
    background-color: $button-color;
    color: white;
    transition: 0.1s ease-in;
  }

  &:active {
    transform: scale(1.1);
  }
}

button.positive {
  $button-color: green;

  border-color: $button-color;
  color: $button-color;

  &:hover {
    background-color: $button-color;
    color: white;
  }
}

button.negative {
  $button-color: red;

  border-color: $button-color;
  color: $button-color;

  &:hover {
    background-color: $button-color;
    color: white;
  }
}

.dinner {
  background-color: rgba(255, 255, 0, 0.5);
  padding: 1em;
  border: 1px dashed red;

  h2 {
    margin-top: 0;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.dinner-part {
  padding: 1em;
  // border: 1px solid grey;
  border-radius: 2px;
  // box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.5);
  background-color: darksalmon;
  font-size: 1.125em;
}

.not-dinner-part {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
}

.glide-in-enter-from,
.glide-in-leave-to {
  opacity: 0;
  // transform: translateY(20px);
  transform: scale(0);
}

.glide-in-enter-active {
  transition: all 0.5s ease-out;
}
</style>
